import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CJumbotron,
  CBreadCrumb,
  LWrap2,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
  AssetImage,
  CRecruitJumbtron,
  CTextImgCard,
  RecruitNews,
  Vertical,
  CBtnList,
  CTextImgCard2,
  CSpTextImgCard
} from "../../components/_index";
import { useMobile } from '../../utils/use-series';
import { size } from "lodash";
import '../../assets/_sass/page/recruit/toppage.scss';
import "../../assets/_sass/page/recruit/common.scss";
import { title } from "process";

// markup
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;
  const verticalData = {
    title: "お知らせ",
    content: "Information",
    cla_left: "cla_left2",
    cla_right: "cla_right2",
    exClass: "container_left_No2",

  };
  const MemberData = {
    title: "社員インタビュー",
    content: "Member Interview",
    cla_left: "cla_left2",
    cla_right: "cla_right2",
    exClass: "container_left_No1",
  }


  return (
    <LayoutSl iscolor={true}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={true} />
      <section className="u_bgWhite_new">
          <CRecruitJumbtron
            data={[
              {
                img: {
                  src: '/assets/images/recruit/kv.png',
                },
                imgSp: {
                  src: '/assets/images/recruit/kv_sp.png',
                },
              },
              // {
              //   img: {
              //     src: '/assets/images/recruit/kv_big.png',
              //   },
              //   imgSp: {
              //     src: '/assets/images/recruit/kv_big_sp.png',
              //   },
              // },
            ]}
          />
      </section>
      <section className="u_bgGray_new recruit-font">
        <LWrap2>
          <CTextImgCard
            data={[
              {
                title: <>「心地よい加減」のおもてなし</>,
                content: (
                  <>
                    私たちは、お客様や共に働く仲間の⽴場で考え、「⼼地よい加減」で寄り添います。<br />
                    その⼟地を想い「街と、もてなす。」ことで、幸せな時間、⾵景、体験を届けます。<br />
                    そして、皆さまとともに「幸せのピースになる」ことを⽬指します。
                    <br />
                  </>
                ),
                imgUrls: [
                  "/assets/images/recruit/img_t.png",
                  "/assets/images/recruit/img_t_02.png",
                  "/assets/images/recruit/img_t_03.png",
                ],
                link: "/recruit/about/",
                linkText: "三菱地所ホテルズ＆リゾーツについて",
                imageUrl: "/assets/images/recruit/goto.svg"
              },
            ]}
            textSideClass="text-down"
          />
        </LWrap2>
      </section>
      <section className="u_bgWhite_new recruit-font">
        <LWrap2>
          <div className="new_main main_news">
            <div className="container_main container_main_1280">
              <Vertical data={verticalData} />
              <RecruitNews />
              <div className="link-news">
                <a href="/recruit/news/" className="news-link">お知らせ一覧
                  <img src="/assets/images/recruit/goto.svg" alt="Link Icon" className="news-image" />
                </a>
              </div>
            </div>
          </div>

        </LWrap2>
      </section>
      <section className="u_bgWhite_new recruit-font">
        <LWrap2>
          <div className="top-message-container">
            <div className="lp_sectWrapper top-area">
              <div className="title_top">
                <div className="title-v">
                  <p>トップメッセージ</p>
                </div>
                <div className="title-en">
                  <p>Top Message</p>
                </div>
              </div>
              <div className="lp_sectSlider2">
                <AssetImage
                  src="/assets/images/recruit/top_message.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </LWrap2>
        <LWrap2>
          <CTextImgCard2
            data={[
              {
                title: <>答えの無い世界に挑み、<br />お客さまの笑顔に答えを見いだす<br />喜びをご一緒に </>,
                content: (
                  <>
                    「心地よい加減」というおもてなしの形を追求する<br className="u_pc" />
                    三菱地所ホテルズ＆リゾーツ。<br />
                    私たちは、街とともに歩み、<br />
                    お客様に心温まる体験を提供しています。<br />
                    「幸せのピースになる」をビジョンに掲げる当社で、<br />
                    次世代のホテリエをともに目指しませんか？<br />
                  </>
                ),
                imgUrls: "/assets/images/recruit/img_02.png",
                link: "/recruit/message/",
                linkText: "トップメッセージ",
                imageUrl: "/assets/images/recruit/goto.svg"
              },
            ]}
            textSideClass="text-up"
            textExClass="wide-side"
            imgExClass="img-wide-side"
          />
        </LWrap2>
      </section>
      <section className="u_bgGray_new recruit-font">
        {!isSp ?
          <LWrap2>

            {/* <LWrap> */}
            <div className="new_main member_new_main">
              <div className="container_main u_bgGray_new member_container_main">
                <Vertical data={MemberData} />
                <div className="member_container_right">
                  <div>
                    <AssetImage
                      src="/assets/images/recruit/interview.png"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="member1">
                    <div className="lp_memberTitle">
                      「人とかかわる」を極めるため<br />
                      アニメ制作の道からホテルマンに軌道修正。<br />
                      入社10年目を前に改めて<br />
                      「意思疎通や連携を大事にする会社」と実感<br />
                    </div>
                    <p><br /></p>
                    <p className="content-text">2015年 新卒入社<br />
                      ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム<br /></p>
                    <p><br /></p>
                    <a href="/recruit/interview/article01/" className="interview-link">インタビューを見る
                      <img src="/assets/images/recruit/goto.svg" alt="Link Icon" className="interview-image" />
                    </a>

                  </div>

                </div>
              </div>
            </div>

            <div className="u_bgGray_new">
              <div className="member_container2">
                <div>
                  <div>
                    <AssetImage
                      src="/assets/images/recruit/interview_02.png"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="member2">
                    <div className="lp_memberTitle">
                      お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。<br />
                      価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に<br />
                    </div>
                    <p><br /></p>
                    <p className="content-text">2020年 新卒入社<br />
                      丸ノ内ホテル 料飲　(レストラン ポム・ダダン) <br /></p>
                    <p><br /></p>
                    <a href="/recruit/interview/article02/" className="interview-link">インタビューを見る
                      <img src="/assets/images/recruit/goto.svg" alt="Link Icon" className="interview-image" />
                    </a>

                  </div>
                </div>
              </div>
            </div>

            <div className="u_bgGray_new">
              <div className="member_container3">
                <div></div>
                <div >
                  <div>
                    <AssetImage
                      src="/assets/images/recruit/interview_03.png"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="member3">
                    <div className="lp_memberTitle">
                      「さまざまな接客を経験したい」と、<br className="u_pc" />これまで
                      経験のないフロント業務ができる当社へ。<br />
                      ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する<br />
                    </div>
                    <p><br /></p>
                    <p className="content-text">2019年 キャリア入社<br />
                      ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム<br /></p>
                    <p><br /></p>
                    <a href="/recruit/interview/article03/" className="interview-link">インタビューを見る
                      <img src="/assets/images/recruit/goto.svg" alt="Link Icon" className="interview-image" />
                    </a>

                  </div>
                </div>
              </div>
            </div>

            <div className="btn-area recruit-font">
              <CBtnList
                exClass="member_btn"
                data={[
                  {
                    label: "社員インタビュー一覧",
                    link: {
                      href: "/recruit/interview/",
                    },
                    icon: "none",
                  },
                ]}
              />
            </div>
          </LWrap2>
          :
          <LWrap2>
            <CSpTextImgCard
              data={{
                titleJp: <>社員インタビュー</>,
                titleEn: <>Member Interview</>,
                content1: <>「人とかかわる」を極めるためアニメ制作の道からホテルマンに軌道修正。<br />
                  入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感</>,
                content3: <>2015年 新卒入社<br />
                  ザ ロイヤルパークホテル アイコニック 東京汐留<br />
                  フロントチーム</>,
                imgUrls: "/assets/images/recruit/interview_sp.png",
                link: "/recruit/interview/article01/",
                linkText: "インタビューを見る"
              }}
              exMainClass="member_main_sp_container"
              textExClass="member1_sp_class"
              exSpLinkClass="interview_member_sp_link"
            />

            <CSpTextImgCard
              data={{
                content1: <>お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。<br />
                  価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に</>,
                content3: <>2020年 新卒入社<br />
                  丸ノ内ホテル 料飲　(レストラン ポム・ダダン) </>,
                imgUrls: "/assets/images/recruit/interview_02_sp.png",
                link: "/recruit/interview/article02/",
                linkText: "インタビューを見る"
              }}
              exMainClass="member_main_sp_container"
              exSpLinkClass="interview_member_sp_link"
            />

            <CSpTextImgCard
              data={{
                content1: <>「さまざまな接客を経験したい」と、これまで
                  経験のないフロント業務ができる当社へ。<br />
                  ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する</>,
                content3: <>2019年 キャリア入社 <br />
                  ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム </>,
                imgUrls: "/assets/images/recruit/interview_03_sp.png",
                link: "/recruit/interview/article03/",
                linkText: "インタビューを見る"
              }}
              exSpLinkClass="interview_member_sp_link"
            />

            <div className="btn-area">
              <CBtnList
                exClass="member_btn"
                data={[
                  {
                    label: "社員インタビュー一覧",
                    link: {
                      href: "/recruit/interview/",
                    },
                    icon: "none",
                  },
                ]}
              />
            </div>

          </LWrap2>

        }

      </section>

      <section className="u_bgWhite_new recruit-font">
        {!isSp ?
          <LWrap2>
            <div className="job-container">
              <div className="job-img">
                <AssetImage src="/assets/images/recruit/img_03.png" alt="" />
              </div>
              <div className="job-text-area">
                <div className="job-title ">
                  <div className="job-title-v">
                    <p>仕事紹介</p>
                  </div>
                  <div className="job-title-en">
                    <p>Job Introduction</p>
                  </div>
                </div>
                <div className="job-text">
                  <p>主な仕事内容を紹介いたします。<br />
                    原則、フルサービス型ホテルでは宿泊・料飲・宴会部門のいずれかから、<br />アイコニックブランドと丸ノ内ホテルでは宿泊・料飲のいずれかから、<br />その他宿泊主体型ホテルでは宿泊部門からキャリアをスタートします。<br />
                    調理職については、原則、高校・専門学校・大学等で調理を学んだ方を対象に募集を行っています。<br /></p>
                  <a href="/recruit/works/" className="job-link">仕事紹介
                    <AssetImage src="/assets/images/recruit/goto.svg" alt="Link Icon" className="job-image" />
                  </a>
                </div>
              </div>
            </div>
          </LWrap2>
          :
          <LWrap2>
            <CSpTextImgCard
              data={{
                titleJp: <>仕事紹介</>,
                titleEn: <>Job Introduction</>,
                content2: <>主な仕事内容を紹介いたします。<br />
                  原則、フルサービス型ホテルでは宿泊・料飲・宴会部門のいずれかから、<br />アイコニックブランドと丸ノ内ホテルでは宿泊・料飲のいずれかから、<br />その他宿泊主体型ホテルでは宿泊部門からキャリアをスタートします。<br />
                  調理職については、原則、高校・専門学校・大学等で調理を学んだ方を対象に募集を行っています。</>,
                imgUrls: "/assets/images/recruit/img_03.png",
                link: "/recruit/works/",
                linkText: "仕事紹介"
              }}
              textExClass="job_sp_class"
            />
          </LWrap2>
        }

      </section>

      <section className="u_bgWhite_new recruit-font">

        {!isSp ? (<div className="img-area">
          <AssetImage src="/assets/images/recruit/img_05.png" alt="Image 1" className="image1" />
          <AssetImage src="/assets/images/recruit/img_06.png" alt="Image 2" className="image2" />
          <AssetImage src="/assets/images/recruit/img_07.png" alt="Image 3" className="image1" />
        </div>)
          :
          (
            <div className="img-area">
              <AssetImage src="/assets/images/recruit/img_05_sp.png" alt="Image 1" className="image1" />
              <AssetImage src="/assets/images/recruit/img_06_sp.png" alt="Image 2" className="image2" />
              <AssetImage src="/assets/images/recruit/img_07_sp.png" alt="Image 3" className="image1" />
            </div>)

        }

        <LWrap2>
          <div className="label-area new_main">
            <CLabelCard exClass="label-exClass-toppage container_main_1280" align="center" data={[
              {
                img: {
                  src: '/assets/images/recruit/img_08.png',
                  alt: ''
                },
                link: {
                  href: '/recruit/data/'
                },
                title: {
                  dataMain: '数字で見る三菱地所ホテルズ＆リゾーツ',
                  sub: <></>
                },
                text: <>三菱地所ホテルズ＆リゾーツの規模や特長を、<br />
                  わかりやすい数字でご紹介します。<br />
                  私たちの強みや働きやすさを、ぜひこのデータから感じ取ってください。</>
              },
              {
                img: {
                  src: '/assets/images/recruit/img_09.png',
                  alt: ''
                },
                link: {
                  href: '/recruit/careerpath/'
                },
                title: {
                  dataMain: 'キャリアパス・研修制度・福利厚生',
                  sub: <></>
                },
                text: <>三菱地所ホテルズ＆リゾーツでは、全てのスタッフが自身の成長を実感できる研修制度とキャリアパスを提供しています。</>
              },
              {
                img: {
                  src: '/assets/images/recruit/img_10.png',
                  alt: ''
                },
                link: {
                  href: '/recruit/faq/'
                },
                title: {
                  dataMain: 'よくあるご質問',
                  sub: <></>
                },
                text: <>応募から入社後まで、皆さまから寄せられるご質問にお答えします。記載の内容以外にも、疑問点や不安なことがありましたら、お気軽にお問い合わせください。</>
              },
            ]} />
          </div>
        </LWrap2>
      </section>

      <section className="u_bgGray_new recruit-font">

        {!isSp ?
          (<LWrap2>
            <div className="re-container u_bgGray_new">
              <div className="re-img">
                <AssetImage
                  src="/assets/images/recruit/img_04.png"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="re-text-area">
                <div className="requirement-text">
                  <p>ロイヤルパークホテルズは、<br />
                    その街ならではのおもてなし、お客様一人ひとりに合った居心地の良さを追求し、日本のおもてなしを象徴する「心地よい加減」のサービスを大切にしています。<br />
                    当社の一員となってともに成長していきませんか。<br /></p>
                  <a href="/recruit/recruitment/" className="job-link">採用情報
                    <img src="/assets/images/recruit/goto.svg" alt="Link Icon" className="job-image" />
                  </a>
                </div>
                <div className="re-title">
                  <div className="re-title-v">
                    <p >採用情報</p>
                  </div>
                  <div className="re-title-en">
                    <p >Recruitment</p>
                  </div>
                </div>
              </div>
            </div>
          </LWrap2>)
          : (
            <LWrap2>
              <div className="re-container u_bgGray_new">
                <CSpTextImgCard
                  data={{
                    titleJp: <>採用情報</>,
                    titleEn: <>Recruitment</>,
                    content4: <>ロイヤルパークホテルズは、<br />
                      その街ならではのおもてなし、お客様一人ひとりに合った居心地の良さを追求し、日本のおもてなしを象徴する「心地よい加減」のサービスを大切にしています。<br />
                      当社の一員となって共にともに成長していきませんか。</>,
                    imgUrls: "/assets/images/recruit/img_04.png",
                    link: "/recruit/recruitment/",
                    linkText: "採用情報"
                  }}
                />
              </div>
            </LWrap2>)}


      </section>


    </LayoutSl>
  );
};
export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
